import React, { useContext, useState } from 'react'
import { graphql, useStaticQuery } from "gatsby";

import { Context } from "@state";

import { Box, Text } from 'rebass'

import Button from '@atoms/Button'

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "axios";

const styles = (paymentProcessing, success) => ({
  form: {
    width: '100%',
    maxWidth: '416px'
  },
  row: {
    width: '100%',
    mx: 'auto',
    mt: [2, 2, 2, 3],
    mb: [5],
    pb: [3],
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    position: 'relative',
  },
  cardElementContainer: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    'form': {
      flexGrow: '1',
      maxWidth: '395px',
      width: '100%'
    },
    '.StripeElement': {
      width: '100%',
      py: '18px',
    }
  },
  submit: {
    textAlign: 'center',
    borderRadius: '12px',
    width: '100%'
  },
  button: {
    mt: '0px',
    backgroundColor: paymentProcessing && !success ? 'gray !important' : (success ? '#728e21' : '#f86955'),
    borderColor: paymentProcessing && !success ? 'gray !important' : (success ? '#728e21' : '#f86955'),
    cursor: paymentProcessing ? 'default' : 'pointer',
    "&:before": {
      backgroundColor: paymentProcessing && !success ? 'gray' : (success ? '#728e21' : '#f86955'),
      boxShadow: paymentProcessing && !success ? 'none !important' : '0 0 0 2px #ef4a32, 0 0.19em 0 0 #fb604bbf'
    }
  },
  error: {
    width: '100%',
    height: 0,
    mx: 'auto',
    position: 'relative',
    textAlign: 'left',
    p: {
      top: '0%',
      fontSize: 1,
      fontWeight: 500,
      transform: `translateY(-200%)`,
      display: 'block',
      color: '#eb1c26'
    }
  }
})

const iframeStyles = {
  base: {
    color: "black",
    fontSize: "14px",
    iconColor: "#F86955",
    "::placeholder": {
      color: "rgba(0, 0, 0, 0.42)"
    }
  },
  invalid: {
    iconColor: "#eb1c26",
    color: "#F86955"
  },
  complete: {
    iconColor: "grass3"
  }
}

const cardElementOpts = {
  iconStyle: "solid",
  style: iframeStyles,
  hidePostalCode: true,
  labels: 'floating',

}

const CheckoutForm = () => {
  const [state, dispatch] = useContext(Context)

  const { paymentProcessing, user, paymentSuccess } = state
  const [checkoutError, setCheckoutError] = useState(false);
  const _styles = styles(paymentProcessing, paymentSuccess)
  const stripe = useStripe()
  const elements = useElements()

  const { markdownRemark: { frontmatter } } = useStaticQuery(graphql`
      {
          markdownRemark {
              frontmatter {
                  paymentButton
              }
          }
      }
  `)

  const setProcessingTo = (value) => {
    dispatch({
      type: 'UPDATE_PAYMENT_PROCESSING',
      payload: value
    });
  }

  const handleCardDetailsChange = (ev) => {
    const { error } = ev
    const message = error ? error.message : false
    setCheckoutError(message)
  }

  const onSuccessfulCheckout = () => {
    dispatch({
      type: "SUCCESS_PAYMENT_PROCESSING",
      payload: true,
    })

    dispatch({
      type: "SEND_FORM",
      payload: true
    })

    setProcessingTo(false)
  }

  const handleFormSubmit = async ev => {
    ev.preventDefault()
    
    if (!user.name || !user.email) {
      dispatch({
        type: "FORM_ERRORS",
      })
      return
    }

    const billingDetails = {
      name: user.name,
      email: user.email,
    };

    setProcessingTo(true);

    try {
      const { data: customerObj } = await axios.post('/.netlify/functions/create_customer',
        {
          name: user.name,
          email: user.email
        }
      )

      const cardElement = elements.getElement("card");

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      })

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { data } = await axios.post("/.netlify/functions/create_subscription", {
        customerId: customerObj.customerID,
        newCustomer: customerObj.newCustomer,
        paymentMethodId: paymentMethodReq.paymentMethod.id,
        email: user.email
      });

      if (data.subscription.hasOwnProperty('error')) {
        setCheckoutError(data.subscription.error.message);
        setProcessingTo(false);
        return;
      }

      onSuccessfulCheckout();
    } catch (err) {
      setCheckoutError(err.message);
    }
  }

  const buttonLabel = paymentProcessing && !paymentSuccess ? "Processing..." : (paymentSuccess ? 'Success' : frontmatter.paymentButton)

  return (
    <Box as={'form'} sx={_styles.form} onSubmit={handleFormSubmit}>
      <Box sx={_styles.row}>
        <Box sx={_styles.cardElementContainer}>
          <CardElement
            onChange={handleCardDetailsChange}
            options={{ ...cardElementOpts }}
          />
        </Box>
      </Box>
      {checkoutError && (
        <Box sx={_styles.error}>
          <Text as={'p'}>{checkoutError}</Text>
        </Box>
      )}
      <Box sx={_styles.submit}>
        <Button
          sx={_styles.button}
          disabled={(paymentProcessing && !paymentSuccess) || !stripe}
          buttonVariant={'card'}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  )
}

export default CheckoutForm