import { useSpring, useSpringRef } from "react-spring";

const useCardAnimation = (sent, state) => {

    const titleRef = useSpringRef()
    const { title } = useSpring({
        title: sent ? 1 : 0,
        config: { mass: 2, tension: 80, friction: 20 },
        ref: titleRef,
    })
    
    const closeRef = useSpringRef()
    const { closeAnimation, foldMargin } = useSpring({
        closeAnimation: sent ? '0px' : '1380px',
        foldMargin: sent ? '80px' : '10vh',
        config: { mass: 20, tension: 900, friction: 10, duration: 2000 },
        ref: closeRef,
    })


    const foldRef = useSpringRef()
    const { contentTransform, transformBack, foldPadding } = useSpring({
        contentTransform: `rotateX(${sent ? 180 : 0}deg)`,
        transformBack: `rotateX(${sent ? 0 : 180}deg)`,
        // bottom spacing AFTER | BEFORE
        foldPadding: sent ? '80px' : '120px',
        config: { mass: 2, tension: 40, friction: 20 },
        ref: foldRef,
        delay: 1200,
        onRest: () => {
            document.getElementById('top-half').scrollIntoView({
                behavior: 'smooth', block: 'start'
            });
        }
    })

    const shakeRef = useSpringRef()
    const { rotate } = useSpring({
        from: { rotate: 0 },
        rotate: sent ? 1 : 0,
        config: { mass: 2, tension: 500, friction: 80 },
        ref: shakeRef,
        delay: 300
    })

    const stampRef = useSpringRef()
    const { stamp } = useSpring({
        from: { stamp: 0 },
        stamp: !sent ? 0 : 1,
        config: { duration: 800 },
        ref: stampRef,
    })
    const stampAnimation = {
        opacity: stamp.to({
            range: [0, 0.2, 1],
            output: [0, '0.86', '0.86'],
        }),
    }

    const hideRef = useSpringRef()
    const { opacity, fly } = useSpring({
        opacity: sent ? 0 : 1,
        from: { fly: 0 },
        fly: sent ? 2 : 0,
        config: { mass: 8, tension: 160, friction: 120, duration: 1000 },
        ref: hideRef,
    })

    const vanishRef = useSpringRef()
    const { disappearAnimation } = useSpring({
        disappearAnimation: sent ? 0 : 1,
        config: { mass: 20, tension: 900, friction: 10, duration: 2000 },
        ref: vanishRef,
    })

    const { loader } = useSpring({
        from: { loader: 0 },
        opacity: state ? 1 : 0,
        loader: state ? 1 : 0,
        config: { duration: 600 },
    })

    const cardAnimation = {
        opacity: opacity,
        rotate: rotate.to({
            range: [0, 0.40, 1],
            output: [0, 2, -5],
        }),
        translateX: fly.to({
            range: [0, 0.1, 2],
            output: [0, 40, 150]
        })
            .to((x) => {
                return `${x}%`
            })
    }

    const loadOpacityAnimation = {
        opacity: loader.to({ range: [0, 1], output: [0, 1] }),
    }

    return {
        title,
        titleRef,
        foldRef,
        contentTransform,
        transformBack,
        shakeRef,
        cardAnimation,
        hideRef,
        foldMargin,
        foldPadding,
        vanishRef,
        disappearAnimation,
        loadOpacityAnimation,
        closeRef,
        closeAnimation,
        stampRef,
        stampAnimation
    }
}

export default useCardAnimation