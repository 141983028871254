import React from 'react'

import { Box, Flex } from 'rebass'

import StripeForm from '@molecules/stripe/StripeForm'

const styles = (sx) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    gridGap: '20px',
    pt: '44px',
    pb: '30px',
    header: {
        width: '100%',
        mx: 'auto',
        justifyContent: 'space-evenly',
    },
    selection: (selected) => ({
        cursor: 'default',
        borderColor: 'black',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '12px',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '600',
        color: '#fff',
        background: selected ? '#f86955d9' : '#FFFFFF02',
        minWidth: '146px',
        width: '44%',
        py: '8px',
        transition: '200ms ease',
        transitionProperty: 'background',
        '&:hover': {
            background: '#f86955',
            color: 'white',
        },
        svg: {
            width: '100%',
            verticalAlign: 'middle'
        }
    }),
    result: {
        width: '100%',
        height: '100%',
        opacity: 1,
        transition: '400ms ease',
        transitionProperty: 'opacity',
        stripe: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paypal: {
            pt: '13%',
            height: '100%',
            width: '100%',
            maxWidth: '475px',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    ...sx
})

const PaymentMethods = ({ sx = {} }) => {
    const _styles = styles(sx)

    return (
        <Box sx={_styles}>
            <Flex sx={_styles.header}>
                <Box sx={_styles.selection(true)}>
                    Thank You
                </Box>
            </Flex>
            <Flex sx={_styles.result.stripe} id="stripe">
                <StripeForm />
            </Flex>
        </Box >
    )
}

export default PaymentMethods