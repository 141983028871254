import React, {useContext, useCallback, useRef} from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import debounce from 'lodash.debounce';
import {useStaticQuery, graphql} from "gatsby"
import {animated} from 'react-spring'
import {Box} from 'rebass'
import {Splide, SplideSlide} from '@splidejs/react-splide'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import usePuppyAnimation from '@hooks/usePuppyAnimation'
import {Context} from "@state";

const splideOptions = {
  autoplay: true,
  focus: "center",
  drag: "free",
  type: "loop",
  pagination: false,
  arrows: false,
  cover: true,
  fixedHeight: '240px',
  pauseOnHover: false,
  pauseOnFocus: false,
  lazyLoad: false,
  perMove: 1,
  perPage: 5,
  gap: -3,
  breakpoints: {
    992: {
      perPage: 4,
      gap: -5,
    },
    // Mobile portrait
    768: {
      perPage: 3,
      gap: -3,
      fixedHeight: '180px',
    },
    // Mobile portrait
    413: {
      perPage: 2,
      gap: -1,
      fixedHeight: '120px',
    }
  }
}


const styles = (sx) => ({
  overflowX: "hidden",
  pt: 0,
  pb: '50px',
  mt: '-16px',
  li: {
    boxShadow: ['rgba(0, 0, 0, 0) 0 0 0', 'rgba(0, 0, 0, 0) 0 0 0', 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'],
    cursor: "grab",
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderStyle: "solid",
    borderRadius: "5px",
    position: "relative",
    transition: "ease",
    transitionProperty: "transform z-index border-color box-shadow",
    transitionDuration: "300ms",
    zIndex: 15,
    "&:nth-of-type(odd)": {
      transform: "scale(1.02) translateY(16px)"
    },
    "&:nth-of-type(even)": {
      transform: "scale(1.02) translateY(-16px)"
    },
    // "&:hover": {
    // },
    // Add grabbing via js
    "&.is-active": {
      zIndex: 16,
      borderColor: "rgba(220, 220, 220, 0.75)",
      transform: "scale(1.06) translateY(10px)",
      boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
    },
    '& .gatsby-image-wrapper': {
      width: '100%',
      height: '100%'
    }
  },
  ".splide__track": {
    overflow: "visible"
  },
  ...sx
})

const BannerHero = ({sx = {}, delay = 1500, duration = 1000}) => {
  const [state] = useContext(Context);
  const {ready} = state

  const {allFile: {nodes: images}} = useStaticQuery(graphql`
      {
          allFile(
              sort: {order: ASC, fields: name}
              filter: {
                  extension: {regex: "/(png|jpe?g)/"},
                  sourceInstanceName: {eq: "data"},
                  relativeDirectory: {eq: "images"}
              }
          ) {
              nodes {
                  childImageSharp {
                      gatsbyImageData
                  }
              }
          }
      }
  `)


  const {puppyAnimation} = usePuppyAnimation(delay, duration, ready)

  const wrapperStyles = styles(sx)

  const ref = useRef()

  const updateSlider = () => {
    if (ref.current.splide) {
      ref.current.splide.refresh()
    }
  }

  const debouncedUpdate = useCallback( debounce(updateSlider, 200) , []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={wrapperStyles}>
      <animated.div style={puppyAnimation}>
        <Box>
          <Splide ref={ref} options={splideOptions}>
            {images.map((puppy, index) => {
              const image = getImage(puppy.childImageSharp)
              return (
                <SplideSlide key={index}>
                  <GatsbyImage
                    loading="eager"
                    onLoad={debouncedUpdate}
                    image={image}
                    alt={`${process.env.GATSBY_SELECTED_SITE}-#${index}`}
                  />
                </SplideSlide>
              )
            })}
          </Splide>
        </Box>
      </animated.div>
    </Box>
  )
}

export default BannerHero
