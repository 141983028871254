import React from "react"

import { PawIcon } from "@atoms/Icon"

import { animated } from "react-spring"

import usePawsAnimation from '@hooks/usePawsAnimation'

const paw = ({ x, y, deg, delay }) => ({
	width: ["14px", "20px", "30px"],
	height: ["14px", "20px", "30px"],
	position: "absolute",
	transform: deg,
	top: y,
	left: x,
	zIndex: "2",
	pointerEvents: "none"
})

const Paws = ({ pawInfo = {}, state }) => {
	const { pawsAnimation } = usePawsAnimation(pawInfo, state)

	return (
		<animated.div style={pawsAnimation}>
			<PawIcon style={paw(pawInfo)} />
		</animated.div>

	)
}

export default Paws
