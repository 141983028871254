import React, {useContext} from "react"
import {useStaticQuery, graphql} from "gatsby"

import { useSpring, animated } from 'react-spring'
import { Box, Text } from 'rebass'

import ListElement from '@atoms/ListElement'

import { Context } from "@state";

const styles = (sx) => ({
	my: 0,
	mx: 'auto',
	minWidth: 0,
	fontSize: [1, 1, '22px', 2, 3, 4],
	maxWidth: ['100%', '100%', '620px', '620px', '800px', '900px'],
	fontWeight: 'bold',
	ul: {
		my: 0,
		listStyle: 'none',
		paddingInlineStart: '0px',
		li: {
			width: ['80%'],
			mx: 'auto',
			mb: 3,
			'&>div': {
				display: 'grid',
				gridTemplateColumns: 'auto 1fr',
				gridColumnGap: ['6px', '10px', '16px'],
				alignItems: 'flex-start',
				justifyContent: 'center',
				'& svg': {
					mt: '2px',
					color: '#fffbc8',
					width: '1em',
					height: '1em'
				},
				div: {
					lineHeight: '1.2',
					color: 'white',
					textShadow: "1px 1px 1px #34460f2e, 1px 2px 2px #34460f17, -1px -1px 1px #7e9a4417",
				}
			}
		}
	},
	...sx
})

const List = ({ sx = {}, delay = 1500, duration = 1000, wiggle = 2 }) => {
	const { markdownRemark: { frontmatter }, allFile: { edges: icon } } = useStaticQuery(graphql`
		{
			markdownRemark {
				frontmatter {
					list
				}
			}
			allFile(
				limit: 1
				filter: {sourceInstanceName: {eq: "data"}, extension: {eq: "svg"}, relativeDirectory: {eq: "images"}}
				sort: {order: DESC, fields: modifiedTime}
			) {
				edges {
					node {
						svg {
							content
						}
					}
				}
			}
		}
	`)

	const [state] = useContext(Context)
	const {ready} = state

	const { y } = useSpring({
		from: { y: 0 },
		y: ready ? 1 : 0,
		config: { duration: duration },
		delay: delay
	})

	const listAnimation = {
		opacity: y.to({ range: [0, 1], output: [0, 1] }),
		scale: y.to({
			range: [0, 1],
			output: [0.9, 1],
		}),
	}

	const wrapperStyles = styles(sx)

	return (
		<Box sx={wrapperStyles}>
			<animated.div style={listAnimation}>
				<ul>
					{frontmatter && frontmatter.list && frontmatter.list.map((item, index) => {
						const text = item.replace('{price}', process.env.GATSBY_PRICE)
						return (
							<ListElement
								state={ready}
								delay={delay + index * 550}
								duration={400}
								key={`${index}-list`}
								itemIcon={{icon: icon[0].node.svg.content, wiggle}}
							>
								<Text>{text}</Text>
							</ListElement>
						)
					})}
				</ul>
			</animated.div>
		</Box>
	)
}

export default List
