import { useSpring } from 'react-spring'

const usePuppyAnimation = (delay, duration, state) => {
    const { puppy } = useSpring({
        from: { puppy: 0 },
        puppy: state ? 1 : 0,
        config: { duration: duration },
        delay: delay
    })

    const puppyAnimation = {
        opacity: puppy.to({ range: [0, 1], output: [0, 1] }),
        translateY: puppy.to({
            range: [0, 1],
            output: [-100, 0],
        }),
        marginTop: puppy.to({
            range: [0, 1],
            output: [-114, -14],
        }),
        marginBottom: puppy.to({
            range: [0, 1],
            output: [100, 0],
        }),
    }

    return { puppyAnimation }

}

export default usePuppyAnimation;