import React, { useState } from 'react'

import { Box } from 'rebass'

import { useSpring, animated, easings } from 'react-spring'

const ListElement = ({ children, delay = 0, duration = 0, state, itemIcon }) => {
    const [hovered, setHovered] = useState(false)

    const hoverHandler = () => setHovered(!hovered)

    const { element } = useSpring({
        from: { element: 0 },
        element: state ? 1 : 0,
        config: { duration: duration },
        delay: delay
    })

    const elementLoadAnimation = {
        opacity: element.to({ range: [0, 1], output: [0, 1] }),
        scale: element.to({
            range: [0, 1],
            output: [0.9, 1],
        }),
    }

    const { iconSpring } = useSpring({
        from: { iconSpring: 0 },
        iconSpring: hovered ? 1 : 0,
        config: {
            easing: easings.easeOutCirc,
            frequency: 1,
            damping: 1,
            tension: 20,
            friction: 30,
            duration: 600
        },
        delay: 0,
    })

    const iconAnimation = {
        position: 'relative',
        left: iconSpring.to({
            range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            output: [-itemIcon.wiggle, itemIcon.wiggle, -itemIcon.wiggle, itemIcon.wiggle, -itemIcon.wiggle, itemIcon.wiggle, -itemIcon.wiggle, itemIcon.wiggle, -itemIcon.wiggle, itemIcon.wiggle, -itemIcon.wiggle]
        }),
    }

    return (
        <li>
            <animated.div style={elementLoadAnimation}>
                <animated.div style={iconAnimation}>
                    <Box
                      onMouseEnter={hoverHandler}
                      dangerouslySetInnerHTML={{ __html: itemIcon.icon }}
                    />
                </animated.div>
                {children}
            </animated.div>
        </li>
    )
}

export default ListElement
