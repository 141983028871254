import React from 'react'
import { Box, Flex, Link } from 'rebass'

const _styles = (sx) => ({
    mt: '0px',
    link: {
        a: {
            mt: '10px',
            mb: '30px',
            width: '100%',
            color: 'white',
            fontSize: ['30px', '30px', 3, 4],
            textAlign: 'center',
            fontWeight: 'bolder',
            textShadow: "2px 2px 1px #34460f2e, 4px 4px 2px #34460f17, -1px 0 #00000030, 0 1px #00000030, 1px 0 #00000030, 0 -1px #00000030",
            textDecoration: 'none',
            transition: '200ms ease',
            transitionProperty: 'transform',
            '&:hover': {
                transform: 'scale(1.1)',
            }
        },
    },
    ...sx
})

const SiblingSite = ({ sisterSiteUrl, children, sx = {} }) => {
    const styles = _styles(sx)

    return (
        <Box sx={styles}>
            <Flex sx={styles.link}>
                <Link href={sisterSiteUrl}>{children}</Link>
            </Flex>
        </Box>
    )
}

export default SiblingSite