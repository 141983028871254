import { useSpring } from "react-spring"

const usePawsAnimation = (pawInfo, state) => {
    const { paw } = useSpring({
        from: { paw: 0 },
        paw: state ? 1 : 0,
        config: {
            duration: 200
        },
        delay: pawInfo.delay,
    })

    const pawsAnimation = {
        opacity: paw.to({ range: [0, 1], output: [0, 1] }),
    }

    return { pawsAnimation }
}

export default usePawsAnimation