import React, { useContext } from 'react';
import { Box, Flex, Text } from 'rebass'
import { Context } from "@state";

const styles = {
    mask: {
        overflow: 'hidden',
        width: '100%',
        pb: '98px',
    },
    title: {
        pb: '16px',
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.18)',
        textAlign: 'center',
        width: '100%',
    },
    wrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(999, max-content)',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    number: (isCompleted, isActual) => ({
        justifyContent: 'center',
        alignItems: 'center',
        color: isCompleted ? '#E7725D' : 'white',
        width: '32px',
        height: '32px',
        transform: isActual ? 'scale(1.22)' : 'scale(1)',
        background: isCompleted ? 'white' : isActual ? '#FFAD7E' : '#E7725D',
        border: 'solid',
        borderWidth: '4px',
        borderColor: isActual ? '#FFAD7E' : '#E7725D',
        borderRadius: '50%',
        transition: '200ms ease',
        transitionProperty: 'transform',
        ml: '1px',
        pt: '1px',
    }),
    separator: {
        content: '" "',
        background: '#E7725D',
        display: 'block',
        width: '40px',
        height: '4px',
    }
}

const SignUpProgress = () => {
    const [state] = useContext(Context);
    const { paymentStep } = state

    const steps = [1, 2, 3]

    return (
        <Box sx={styles.mask}>
            <Text sx={styles.title} as={'p'}>Signup Process</Text>
            <Box sx={styles.wrapper}>
                {steps.map((step, index) => {
                    const isCompleted = paymentStep > step
                    const isActual = step === paymentStep
                    const isLast = step === steps.length
                    return (
                        <React.Fragment key={`progress-${index}`}>
                            <Flex sx={styles.number(isCompleted, isActual)} index={`${step}-${index}`}>
                                {step}
                            </Flex>
                            {!isLast && <Box sx={styles.separator} />}
                        </React.Fragment>
                    )
                })}
            </Box>
        </Box>
    );
};

export default SignUpProgress;