import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

import { Context } from "@state";

import "normalize.css"

import SubscriptionLetter from "@organisms/SubscriptionLetter"
import Layout from '@organisms/Layout'

import ButtonHero from '@molecules/ButtonHero'
import BannerHero from '@molecules/BannerHero'
import ListHero from '@molecules/ListHero'
import HidingPlate from '@organisms/HidingPlate'

import Title from '@atoms/Title'

const overlapping = (index) => ({
  position: 'relative',
  zIndex: index,
})

const titleStyles = {
  mt: ['0px', '0px', '0px', '14px', '24px'],
  span: {
    '&:first-of-type': {
      border: '6px solid #ffe354',
      padding: ['0 19px', '0 22px', '0 26px', '0 32px'],
      fontSize: ['22px', '26px', '28px', '32px', '53px', '57px'],
      // fontSize: '57px',
      fontWeight: 'bolder',
      color: '#ffee95',
    },
    '&:last-of-type': {
      display: 'block',
      fontSize: ['16px', '18px', '20px'],
      marginTop: '19px',
      marginBottom: ['30px', '40px', '50px'],
    }
  }
}

const IndexPage = () => {
  const { markdownRemark: { frontmatter } } = useStaticQuery(graphql`
      {
          markdownRemark {
              frontmatter {
                  title
                  mainButton
              }
          }
      }
  `)

  const [, dispatch] = useContext(Context);

  const [mounted, setMounted] = useState(false)

  const animationSpeed = 0.5

  useEffect((mounted) => {
    if (!mounted && dispatch) {

      dispatch({
        type: "SET_READY",
        payload: true
      });

      setMounted(!mounted)
    }
  }, [mounted, dispatch])

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <HidingPlate>
        <BannerHero
          sx={overlapping(2)}
          delay={2000 * animationSpeed}
          duration={1000 * animationSpeed}
        />
        <Title
          sx={{ ...overlapping(2), ...titleStyles }}
          delay={500 * animationSpeed}
          duration={1000 * animationSpeed}>
          <span>{frontmatter.title}</span><span></span>
        </Title>
        <ListHero
          sx={overlapping(2)}
          delay={3000 * animationSpeed}
          duration={500 * animationSpeed}
        />
        <ButtonHero
          sx={overlapping(1)}
          wiggle={2 * animationSpeed}
          delay={4500 * animationSpeed}
          jumpDelay={400 * animationSpeed}
          animationSpeed={animationSpeed}
        >{frontmatter.mainButton}</ButtonHero>
      </HidingPlate>
      <SubscriptionLetter />
    </Layout>
  )
}

export default IndexPage
