import { useSpring, easings } from "react-spring"

const useMainButtonAnimations = (wiggle, delay, jumpDelay, state, animationSpeed) => {
    const getStepDelay = (step) => {
        return delay + ((step * 400) * animationSpeed)
    }

    // ball spring
    const { ball } = useSpring({
        from: { ball: 0 },
        ball: state ? 1 : 0,
        config: {
            easing: easings.easeOutCirc,
            mass: 1,
            velocity: 1,
            friction: 1,
            duration: 2800
        },
        delay: jumpDelay + getStepDelay(13),
    })

    const ballAnimation = {
        left: ball.to({ range: [0, 1], output: ['12%', '50%'] }),
        backgroundPositionX: ball.to({ range: [0, 1], output: ['1000%', '0%'] }),
    }

    // button spring
    const { _wiggle } = useSpring({
        from: { _wiggle: 0 },
        _wiggle: state ? 1 : 0,
        config: {
            easing: easings.easeOutCirc,
            frequency: 1,
            damping: 1,
            tension: 20,
            friction: 30,
            duration: 600
        },
        delay: jumpDelay + getStepDelay(22),
    })

    const buttonAnimation = {
        position: 'relative',
        zIndex: 10,
        left: _wiggle.to({
            range: [
                0,
                0.1,
                0.2,
                0.3,
                0.4,
                0.5,
                0.6,
                0.7,
                0.8,
                0.9,
                1
            ],
            output: [
                -wiggle,
                wiggle,
                -wiggle,
                wiggle,
                -wiggle,
                wiggle,
                -wiggle,
                wiggle,
                -wiggle,
                wiggle,
                -wiggle
            ]
        }),
    }

    return {
        ballAnimation,
        buttonAnimation,
        getStepDelay
    }
}

export default useMainButtonAnimations