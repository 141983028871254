import React, { useContext } from "react"

import { Context } from "@state";

import { Heading } from "rebass"
import { useSpring, animated } from "react-spring"

const styles = (sx) => ({
    textAlign: 'center',
    maxWidth: '100%',
    px: '20px',
    mx: 'auto',
    fontWeight: 'bolder',
    color: 'white',
    textShadow: "0 0 black",
    ...sx
})

const Title = ({ children, delay = 0, duration = 1000, sx, ...props }) => {
    const [state] = useContext(Context)
    const { ready } = state
    const _styles = styles(sx)
    const { title } = useSpring({
        from: { title: 0 },
        title: ready ? 1 : 0,
        config: { duration: duration },
        delay: delay
    })

    const titleAnimation = {
        opacity: title.to({ range: [0, 1], output: [0, 1] }),
        scale: title.to({
            range: [0, 1],
            output: [0.9, 1],
        }),
    }

    return (
        <animated.div style={titleAnimation}>
            <Heading as={'h2'} sx={_styles}>{children}</Heading>
        </animated.div>
    )
}

export default Title