import React, { useContext } from "react";
import { Context } from "@state";
import { Box } from "rebass";

const HidingPlate = ({ children }) => {
    const [state] = useContext(Context)
    const { sent } = state

    sent && (document.querySelector("html").style.overflow = 'hidden')

    return (
        <>
            {!sent && (<Box>{children}</Box>)}
        </>
    )
}

export default HidingPlate