import React from 'react'

import CheckoutForm from '@molecules/stripe/CheckoutForm'

import { Elements } from '@stripe/react-stripe-js'

import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISH_KEY}`)

const StripeForm = () => {

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    )
}

export default StripeForm