import React, { useContext } from 'react'
import { Context } from "@state";

import { Flex } from 'rebass'

import Button from '@atoms/Button'

import Paws from "@molecules/Paws"

import useMainButtonAnimations from "@hooks/useMainButtonAnimations"

import soccer from '@images/soccer.jpg'

import { animated } from "react-spring"

const styles = (sx) => ({
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    maxWidth: '1200px',
    mx: 'auto',
    mt: ['28px', '30px', '32px', '42px', '52px', '56px'],
    button: {
        zIndex: 10,
    },
    '&>div:not(last-of-type)': {
        zIndex: 8,
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        svg: {
            width: ['16px', '16px', '18px', '24px', '28px', '32px'],
            height: ['16px', '16px', '18px', '24px', '28px', '32px'],
            fill: '#45691ca8'
        }
    },
    '&>div:last-of-type': {
        position: 'absolute',
        zIndex: 0,
        top: ['30%', '24%', '24%', '20%', '26%', '30%'],
        background: `url(${soccer}) scroll`,
        backgroundPosition: '50% 50%',
        backgroundSize: '220%',
        boxShadow: '6px 6px 1px rgba(88, 104, 56, 0.6)',
        width: ['18px', '20px', '22px', '34px', '36px', '40px'],
        height: ['18px', '20px', '22px', '34px', '36px', '40px'],
        borderRadius: '50%',
        transition: 'all 0.7s ease'
    },
    ...sx
})

const ButtonHero = ({ sx = {}, wiggle = 3, delay = 0, jumpDelay = 500, children, animationSpeed }) => {
    const [state] = useContext(Context)
    const { ready } = state

    const {
        ballAnimation,
        buttonAnimation,
        getStepDelay
    } = useMainButtonAnimations(wiggle, delay, jumpDelay, ready, animationSpeed)

    const pawsData = [
        { y: "-572%", x: "7.6%", deg: "rotate(160deg)", delay: getStepDelay(1) },
        { y: "-520%", x: "5.2%", deg: "rotate(162deg)", delay: getStepDelay(2) },
        { y: "-458%", x: "9.2%", deg: "rotate(175deg)", delay: getStepDelay(3) },
        { y: "-412%", x: "7.2%", deg: "rotate(180deg)", delay: getStepDelay(4) },
        { y: "-346%", x: "9.2%", deg: "rotate(192deg)", delay: getStepDelay(5) },
        { y: "-310%", x: "5.6%", deg: "rotate(207deg)", delay: getStepDelay(6) },
        { y: "-244%", x: "5.4%", deg: "rotate(202deg)", delay: getStepDelay(7) },
        { y: "-200%", x: "2.4%", deg: "rotate(208deg)", delay: getStepDelay(8) },
        { y: "-128%", x: "3.8%", deg: "rotate(186deg)", delay: getStepDelay(9) },
        { y: "-80%", x: "1.2%", deg: "rotate(175deg)", delay: getStepDelay(10) },
        { y: "-28%", x: "5.6%", deg: "rotate(140deg)", delay: getStepDelay(11) },
        { y: "30%", x: "5%", deg: "rotate(125deg)", delay: getStepDelay(12) },
        { y: "8%", x: "10%", deg: "rotate(88deg)", delay: jumpDelay + getStepDelay(13) },
        { y: "54%", x: "10.2%", deg: "rotate(98deg)", delay: jumpDelay + getStepDelay(13) },
    ]

    const wrapperStyles = styles(sx)


    const scroll = () => {
        let element = document.getElementById('subscribe-section');
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <Flex sx={wrapperStyles}>
            <animated.span style={buttonAnimation}>
                <Button
                    delay={1000}
                    state={ready}
                    id='main-button'
                    onClick={scroll}
                >{children}</Button>
            </animated.span>
            {pawsData.map((paw, index) => (
                <Paws key={index} pawInfo={paw} state={ready} />
            ))}
            <animated.div style={ballAnimation} />
        </Flex >
    )
}

export default ButtonHero