import React, { useContext } from "react"

// import axios from "axios";

import { Box } from "rebass"

// import LocalizationProvider from '@mui/lab/LocalizationProvider'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import DatePicker from '@mui/lab/DatePicker'

import TextField from '@mui/material/TextField'
import {createTheme} from '@mui/material/styles'
import {Box as InputBox} from '@mui/material'
import {ThemeProvider} from "@mui/material"

import { Context } from "@state";
import Button from "@atoms/Button"
import {graphql, useStaticQuery} from "gatsby";

const UserForm = ({ sx = {} }) => {
  const [state, dispatch] = useContext(Context)
  const {
    user: {name, email},
    formErrors,
    paymentStep
  } = state

  const { markdownRemark: { frontmatter } } = useStaticQuery(graphql`
      {
          markdownRemark {
              frontmatter {
                  continueButton
              }
          }
      }
  `)

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!name || !email) {
      dispatch({
        type: "FORM_ERRORS",
      })
      return
    }


    dispatch({
      type: 'UPDATE_PAYMENT_STEP',
      payload: paymentStep + 1
    })
  };

  const styles = (sx) => ({
    card: {
      borderRadius: '8px',
      pt: 4,
      pb: 3,
      transition: '200ms ease-in-out',
      transitionProperty: 'box-shadow'
    },
    box: {
      width: '416px',
      mx: 'auto',
      mb: '30px',
      maxWidth: '100%',
    },
    buttonStyles: {
      backgroundColor: '#f86955',
      borderColor: '#f86955',
    },
    ...sx
  })

  const _styles = styles(sx)

  const handleUserInput = (e) => {
    if (!e.target.id) {
      return
    }

    const key = e.target.id.replace('user-', 'UPDATE_').toUpperCase()
    const value = e.target.value

    dispatch({
      type: key,
      payload: value
    });
  }

  return (
    <ThemeProvider theme={createTheme({
      palette: {
        primary: {
          main: '#8bc34a',
        },
        secondary: {
          main: '#eda942',
        },
      }
    })}>
      <Box as={'form'} id="payment-form" onSubmit={handleSubmit} sx={_styles}>
        <Box sx={_styles.card}>
          <InputBox sx={_styles.box}>
            <TextField
              required
              autoComplete="email"
              value={email ?? ''}
              onChange={handleUserInput}
              id="user-email"
              fullWidth
              type="email"
              label="Email"
              variant="standard"
              error={formErrors.email}
            />
          </InputBox>

          <InputBox sx={_styles.box}>
            <TextField
              required
              onChange={handleUserInput}
              id="user-name"
              value={name ?? ''}
              fullWidth type="text"
              label="Name"
              variant="standard"
              error={formErrors.name}
            />
          </InputBox>
        </Box>

        <Box sx={{textAlign: 'center'}}>
          <Button
            sx={_styles.buttonStyles}
            buttonVariant={'card'}
          >
            {frontmatter.continueButton}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default UserForm