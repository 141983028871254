import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Context } from "@state";

import Confetti from 'react-confetti'

import paperBack from '@images/paper.webp'
import pawStamp from '@images/pawStamp.png'

import { DogHouseIcon2 } from '@atoms/Icon'

import SiblingSite from '@molecules/SiblingSite'
import FormSteps from '@molecules/FormSteps'
import SignUpProgress from '@molecules/SignUpProgress'


import useCardAnimation from '@hooks/useCardAnimation'

import { Box, Text, Image } from 'rebass'

import { useChain, animated } from 'react-spring'

const styles = (sx) => ({
  header: {
    position: 'relative',
    display: 'grid',
    mt: '-68px',
    gridTemplateColumns: [
      'auto 45px',
      'auto 55px',
      'auto 65px',
      'auto 75px',
      'auto 85px'
    ],
    gridColumnGap: '32px',
    inline: {
      display: 'inline-flex',
      gridColumnGap: '14px',
      flexWrap: 'wrap',
      alignItems: 'flex-end'
    },
    h2: {
      fontSize: [
        '1.2rem',
        '1.4rem',
        '1.8rem',
        '2.5rem'
      ],
      color: '#f96856f2',
      lineHeight: '1.2em',
      width: 'max-content'
    },
    span: {
      fontSize: [
        '1.2rem',
        '1.4rem',
        '1.8rem',
        '2.5rem'
      ],
      fontWeight: 'body',
      lineHeight: '1.2em',
      color: 'rgba(149, 184, 148, 1)',
      width: 'max-content'
    },
    h3: {
      fontSize: [
        '1.2rem',
        '1.4rem',
        '1.8rem',
        '2.5rem'
      ],
      pr: ['0px', '0px', '30px'],
      color: '#f96856f2',
      width: '100%',
    },
    svg: {
      opacity: 0.89,
      height: 'auto',
      width: '100%',
    }
  },
  stylesTitle: {
    textAlign: "center",
    fontSize: ['34px', '34px', 4, 5, 6, '86px'],
    lineHeight: '140%',
    pt: ['2px', '8px', '8px', '8px', '8px'],
    mb: '32px',
    fontWeight: 'bolder',
    color: 'white',
    textShadow: "2px 2px 1px #34460f2e, 4px 4px 2px #34460f17, -1px 0 #00000030, 0 1px #00000030, 1px 0 #00000030, 0 -1px #00000030",
    width: '100%'
  },
  subscribeSection: {
    position: 'relative',
    py: [4, 4, 4, 2],
  },
  card: {
    wrapper: {
      position: 'relative',
      boxShadow: 'none !important',
      background: 'none !important',
      borderRadius: '8px',
      perspective: 3500,
      display: 'block',
      width: '100%',
      maxWidth: '760px',
      mx: 'auto',
    },
    title: {
      textAlign: 'center',
      position: 'absolute',
      top: '10%',
      width: '100%',
      px: [3, 4],
    },
    transformBack: {
      backfaceVisibility: 'hidden',
      background: 'white',
      backgroundImage: `url(${paperBack})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '8px'
    },
    paper: {
      px: '22px',
      py: '24px',
      background: 'rgba(254, 253, 254, 1)',
      borderRadius: '8px',
    },
    top: {
      boxShadow: '0px 45px 67px rgba(0, 0, 0, 0.12), 0px 22.522px 33.5327px rgba(0, 0, 0, 0.0912399), 0px 13.5662px 20.1985px rgba(0, 0, 0, 0.0781907), 0px 8.69392px 12.9443px rgba(0, 0, 0, 0.0685074), 0px 5.6347px 8.38944px rgba(0, 0, 0, 0.06), 0px 3.54698px 5.28106px rgba(0, 0, 0, 0.0514926), 0px 2.03796px 3.0343px rgba(0, 0, 0, 0.0418093), 0px 0.896959px 1.33547px rgba(0, 0, 0, 0.0287601)',
      zIndex: 1
    },
    bottom: {
      boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, 0px 45px 67px rgba(0, 0, 0, 0.12), 0px 22.522px 33.5327px rgba(0, 0, 0, 0.0912399), 0px 13.5662px 20.1985px rgba(0, 0, 0, 0.0781907), 0px 8.69392px 12.9443px rgba(0, 0, 0, 0.0685074), 0px 5.6347px 8.38944px rgba(0, 0, 0, 0.06), 0px 3.54698px 5.28106px rgba(0, 0, 0, 0.0514926), 0px 2.03796px 3.0343px rgba(0, 0, 0, 0.0418093), 0px 0.896959px 1.33547px rgba(0, 0, 0, 0.0287601)',
      transformOrigin: 'center top',
      backfaceVisibility: 'hidden',
      position: 'absolute',
      height: '100%',
      top: 'calc(100% + 2px)',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 20,
    },
    insideBorder: {
      px: [3, 4, 4, '56px', '108px', '92px'],
      pt: '44px',
      pb: ['20px', 4, 4],
      borderColor: '#F29B9191',
      borderWidth: '8px',
      borderStyle: 'solid',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    decorations: {
      content: '" "',
      width: '100%',
      height: '10px',
      display: 'block',
      backgroundImage: 'repeating-linear-gradient(135deg, #F29B91 0px, #F09290 15px, transparent 15px, transparent 25px, #83B3DB 25px, #84ADCB 40px, transparent 40px, transparent 50px)',
      position: 'absolute',
      left: 0,
    },
    stamp: {
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'none',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      transform: 'rotateX(180deg)',
      h3: {
        mt: 0,
        fontSize: 8
      }
    }
  },
  ...sx
})

const SubscriptionLetter = ({ sx = {} }) => {
  const { markdownRemark: { frontmatter } } = useStaticQuery(graphql`
      {
          markdownRemark {
              frontmatter {
                  cardTitle
                  finalMessage
                  stampText
                  sisterSiteUrl
                  sisterSite
              }
          }
      }
  `)

  const _styles = styles(sx)

  const [state] = useContext(Context)

  const { ready, sent, paymentStep } = state

  const {
    title,
    titleRef,
    foldRef,
    contentTransform,
    transformBack,
    shakeRef,
    cardAnimation,
    hideRef,
    foldMargin,
    foldPadding,
    vanishRef,
    disappearAnimation,
    loadOpacityAnimation,
    stampRef,
    stampAnimation
  } = useCardAnimation(sent, ready)

  useChain([
    foldRef,
    stampRef,
    shakeRef,
    hideRef,
    titleRef,
    vanishRef
  ])

  const topHalfStyles = {
    ..._styles.card.paper,
    ..._styles.card.top,
  }

  const bottomHalfStyles = {
    ..._styles.card.paper,
    ..._styles.card.bottom
  }

  const containerStyles = {
    paddingTop: foldMargin,
    paddingBottom: foldPadding
  }

  const topDecorationStyles = {
    ..._styles.card.decorations,
    top: '1px',
    borderRadius: '8px 8px 0 0'
  }

  const bottomDecorationStyles = {
    ..._styles.card.decorations,
    bottom: '-1px',
    borderRadius: '0 0 8px 8px'
  }

  const confettiStyles = {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
  }

  return (
    <Box as={animated.div} style={containerStyles}>
      <Box as={animated.div} sx={_styles.subscribeSection} style={loadOpacityAnimation} id="subscribe-section">
        <Box as={animated.div} style={cardAnimation}>
          <Box sx={_styles.card.wrapper}>
            <Box sx={topHalfStyles} id="top-half">
              <Box sx={{ ..._styles.card.insideBorder, height: '100%' }}>
                <SignUpProgress paymentStep={paymentStep} />
                <Box sx={_styles.header}>
                  <Box sx={_styles.header.inline}>
                    <Text as={'h2'}>{frontmatter.cardTitle[0]}</Text>
                    <Text as={'span'}>{frontmatter.cardTitle[1]}</Text>
                  </Box>
                  <DogHouseIcon2 />
                </Box>
                <FormSteps />
              </Box>
            </Box>

            {sent &&
              <Box as={animated.div} sx={bottomHalfStyles} style={{ transform: contentTransform }} id="bottom-half">
                <Box sx={{ ..._styles.card.insideBorder, height: '100%' }}>
                </Box>
                <Box as={animated.div} style={{ transform: transformBack }} sx={_styles.card.transformBack} id="back-half">
                  <Box sx={topDecorationStyles} />
                  <Box as={animated.div} style={stampAnimation} sx={_styles.card.stamp} id="stamp">
                    <Image src={pawStamp} />
                    <Text sx={{ width: '100%' }} as={'h3'}>{frontmatter.stampText}</Text>
                  </Box>
                  <Box sx={bottomDecorationStyles} />
                </Box>
              </Box>}
          </Box>
        </Box>

        <Box as={animated.div} style={{ opacity: title, pointerEvents: sent ? 'all' : 'none' }} sx={_styles.card.title} id="title" >
          <Text sx={_styles.stylesTitle} as={'h2'}>{frontmatter.finalMessage}</Text>
          <SiblingSite sisterSiteUrl={frontmatter.sisterSiteUrl}>{frontmatter.sisterSite}</SiblingSite>
        </Box>
      </Box>

      <Box as={animated.div} style={{ opacity: title }} id="confetti">
        <Box Box as={animated.div} style={{ opacity: disappearAnimation }}>
          {sent && <Confetti style={confettiStyles} numberOfPieces={400} colors={['#eda942', '#f9705f', '#fffbc8', '#65a000']} />}
        </Box>
      </Box>
    </Box>
  )
}

export default SubscriptionLetter