import React, {useContext} from 'react';
import { Box } from 'rebass'
import {animated, useSpring} from "react-spring";
import UserForm from '@molecules/UserForm'
import PaymentMethods from '@molecules/PaymentMethods'
import { Context } from "@state";

const styles = {
  mask: {
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: 'calc(200% + 40px)',
    gridGap: '40px'
  }
}

const FormSteps = () => {
  const [state] = useContext(Context);
  const { paymentStep } = state

  const { translateX } = useSpring({
    from: { translateX: 0 },
    translateX: paymentStep === 2 ? 1 : 0,
    config: { mass: 4, tension: 600, friction: 100 },
  })

  return (
    <Box sx={styles.mask}>
      <Box
        as={animated.div}
        sx={styles.wrapper}
        style={{
          translateX: translateX.to({
            range: [0, 1],
            output: [0, -50]
          })
            .to((x) => {
              if (paymentStep !== 1) {
                return `calc( ${x}% - 20px)`
              }
              return `${x}%`
            })
        }}
      >
        <UserForm />
        <PaymentMethods />
      </Box>
    </Box>
  );
};

export default FormSteps;